<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="项目详情"
    width="500px"
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">
        <el-form-item label="项目名称" style="color:#4C71DB">  
           {{info.name}}
        </el-form-item>

        <el-form-item label="项目简介">  
         {{info.description}}
        </el-form-item>

        <el-form-item label="项目开始时间">
          {{info.start_at_date}}
        </el-form-item>


        <el-form-item label="项目结束时间">
          {{info.end_at_date}}
        </el-form-item>

        <el-form-item label="主管名">
          {{info.nickname}}
        </el-form-item>
        

        <el-form-item label="审核状态" >
          {{info.status_type}}
        </el-form-item>


        <el-form-item label="审核" v-if="info.status==0" required>
          <el-radio-group v-model="formData.status" size="small">
            <el-radio label="1" border>通过</el-radio>
            <el-radio label="2" border>拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝理由"  v-if="info.status==0" required >
          <el-input 
            type="textarea" 
            v-model="formData.reason"  
            :rows="4"
            placeholder="输入拒绝原因"></el-input>
        </el-form-item>

      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit"  v-if="info.status==0" >确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>
import { projectInfo,projectExamine } from "@/api/project/index";


export default {

  data() {
    return {
      visible: false,
      isSend:1,//1 send 2 receive
      formData:{
        project_id:"",
        status:'1',
        reason:'',

      },
      project_id:"",
      info: {
        project_id:'',
        name:'',
        image:'',
        description:'',
        start_at_date:'',
        end_at_date:'',
        status_type:'',
        nickname:'',
        avatar:'',
        created_at:'',
      },
       rules: {
        

       }
    };
  },

 
  mounted() {

  },

  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init(project_id) {
      this.visible = true;
      this.formData.project_id=project_id;
      this.initInfo();

    },

    initInfo(){
      var that = this;
      projectInfo({"project_id":that.formData.project_id}).then((response) => {
        if(response.code==200){
          that.info = response.data;

        }else{
          that.$message.warning(response.msg?response.msg:"获取信息失败");
        }
      });
    },

    
    submit(){
      var that=this;
      if(this.formData.reason==""&&that.formData.status==2){
        that.$message.warning("请输入拒绝理由");
        return;
      }
      
      this.$refs["form"].validate((valid) => {
          if (valid) {
            projectExamine(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'
                });
                that.visible = false;
                that.$emit("refresh")
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });
    },

  },

 
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item .el-form-item__label {
  color: #9b9b9c !important;
}
/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
